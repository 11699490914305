<template>
  <div class="page-list personnelfiles flex-1">
    <a-row class="filter-wrap  ">
      <a-col
        :lg="8"
        :md="24"
        :sm="24"
        :xs="24"
        style="margin-left:0px;"
        class="mgb-20"
      >
        <a-button
          type="primary"
          class="table-btn-clk"
          style="margin-right:19px;margin-left: 0px;"
          @click="addRules"
        >添加角色</a-button>
      </a-col>
      <a-col
        :lg="16"
        :md="24"
        :sm="24"
        :xs="24"
        class="text-right flex align-item-center j-end "
      >
        <span style="margin-right:-21px;">选择系统：</span>
        <a-select
          allow-clear
          class="mgl-20 mgr-20 pgb-20 width-min-164"
          v-model="query.system_id"
          placeholder="选择系统"
        >
          <a-select-option
            v-for="it in sysList"
            :key="it.system_id"
            :value="it.system_id"
          >
            {{it.system_name}}
          </a-select-option>
        </a-select>
        <span style="margin-right:-21px;">状态：</span>
        <a-select
          allow-clear
          class="mgl-20  mgr-20 pgb-20 width-min-164"
          v-model="query.role_status"
          placeholder="选择状态"
        >
          <a-select-option
            v-for="it in openValue"
            :key="it.id"
            :value="it.id"
          >
            {{it.name}}
          </a-select-option>
        </a-select>

        <span style="margin-right:-21px;">角色昵称：</span>
        <a-input
          @search="getList"
          @pressEnter="getList"
          v-model="query.role_name"
          placeholder="请输入角色昵称"
          class="mgl-20 mgl-20 ant-input2"
          style="width: 168px;"
        >
        </a-input>

        <a-button
          class="mgl-20 table-btn-clk"
          type="primary"
          style="height:32px;margin-top:1px;"
          @click="getList()"
        >查询</a-button>
      </a-col>
    </a-row>
    <a-table
      class="table-manage flex-1"
      @change="tableChanged"
      :pagination="table.pagination"
      row-key="role_id"
      :loading="table.loading"
      :columns="config.rules.columns"
      :data-source="table.tableData"
    >
      <template
        slot="_3"
        slot-scope="status, record"
      >
        <a-switch
          :default-checked="record.role_status?true:false"
          @change="changeStatus($event,record)"
        ></a-switch>
      </template>
      <template
        slot="_7"
        slot-scope="action, record"
      >
        <a-button
          size="small"
          class="mgr-12 smallbtn"
          @click="addRules(record,3)"
        >编辑</a-button>
        <a-button
          size="small"
          class="mgr-12 smallbtn"
          @click="handleBatch('delete',[record],'提示')"
        >删除</a-button>
      </template>
    </a-table>
    <!-- 编辑添加身份 -->
    <a-modal
      :visible="permissionInfo.visible"
      width="750px"
      wrapClassName="commonModel"
      @cancel="permissionInfo.visible=false"
      :okText="permissionInfo.text"
      @ok="okclickmod"
      destroyOnClose
      :bodyStyle="{height: '258px'}"
    >
      <div class="constmodel">
        <div class="linebox flex align-item-center">
          <div class="lfbox"><span class="imgxing">*</span> 角色名称：</div>
          <div class="rgbox">
            <a-input
              v-model="permissionInfo.info.role_name"
              placeholder="请输入角色名称"
            ></a-input>
          </div>
        </div>
        <div class="linebox flex align-item-center">
          <div class="lfbox"><span class="imgxing">*</span> 是否开启：</div>
          <div class="rgbox">
            <a-radio-group
              v-model="permissionInfo.info.role_status"
              @change="onChangePermissiontype"
            >
              <a-radio :value="1">
                开启
              </a-radio>
              <a-radio :value="0">
                关闭
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div class="linebox flex">
          <div
            class="lfbox"
            style="padding-left:12px;"
          > 权限：</div>
          <div class="rgbox">
            <a-tree
              v-model="permissionInfo.info.permission_id_list"
              checkable
              :replaceFields="selectreeObj"
              :tree-data="treeData"
              @expand="onExpand"
              @select="onSelect"
            />
          </div>
        </div>
      </div>
      <template slot="title">
        <div style="margin: 3px 0;">
          <span class="titmodel">{{ permissionInfo.title }}</span>
        </div>
      </template>
    </a-modal>
  </div>

</template>
<script> 
import { Getruleslist, Getrulesinfo, EditRules, createRules, SystemrolrsList, Getrolepermission } from '@/api/permission'
import config from './config'

export default {
  components: {},
  data() {
    return {
      config,
      activeComp: null,
      batchVisible: false,
      sysList: [], //系统列表
      openValue: [
        { id: 1, name: '显示' },
        { id: 0, name: '隐藏' },
      ],// 规则状态 
      query: {
        system_id: undefined,
        role_name: undefined,
        role_status: undefined,
        per_page: 10,
        page: 1,
      },
      table: {
        tableData: [],
        selectedRowKeys: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      },
      expandedRowKeys: [],//树形
      parentId: undefined,
      permissionInfo: {
        visible: false,
        text: '提交',
        title: '',
        type: 1,//1新增   3编辑
        pic_url_loading: false,
        isDisabled: false,
        placeholder: '请选择权限',
        info: {
          role_id: undefined,
          role_name: undefined,
          permission_id_list: [],
          role_status: undefined,
          system_id: undefined
        }
      },
      autoExpandParent: true,
      expandedKeys: ['1', '2'],
      checkedKeys: ['1'],
      treeData: [],
      selectreeObj: {
        children: 'children',
        title: 'permission_name',
        key: 'permission_id',
        value: 'permission_id'
      },
    }
  },
  async created() {
    this.getSystemList()
  },
  methods: {
    handleMenuClick({ key }) {
      this.handleBatch(key)
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.info.pic_url_loading = true
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        const { file_url } = info.file.response.data
        this.permissionInfo.info.permission_icon = file_url
        this.permissionInfo.info.pic_url_loading = false
      }
    },
    // 删除 权限
    handleBatch(key, record, title = "删除") {
      let self = this
      let deleteRules = ''
      const urlArr = [
        { name: deleteRules, key: 'delete', okType: 'primary', msg: '确定要删除吗？删除后将无法恢复，请谨慎操作！' },
      ]
      const activeObj = urlArr.filter(it => it.key == key)[0]
      self.$confirm({
        icon: 'exclamation-circle',
        closable: true,
        title,
        content: activeObj.msg,
        okText: '确定',
        closable: true,
        okType: activeObj.okType,
        cancelText: '取消',
        onOk() {
          const data = {
            employee_ids: selectedRowKeys,
          };
          (activeObj.name)(data).then(res => {
            self.getList()
          }).finally(r => {
          })
        },
        onCancel() {
        },
      });
    },
    getList() {
      let self = this
      self.table.loading = true
      const params = {
        filter: {
          role_status: self.query.role_status,
          role_name: self.query.role_name,
          system_id: self.query.system_id
        },
        page: self.query.page,
        per_page: self.query.per_page
      }

      return Getruleslist(params).then(res => {
        console.log(res.data, "data");
        const { list = [], total = 0 } = res.data
        self.table.tableData = list
        self.table.pagination = Object.assign({}, { pageSize: this.query.per_page, current: this.query.page }, {
          total: total
        })
        self.table.tableData.forEach(e => {
          console.log(e.permission_list.flat(Infinity), 1111);
          var arr2 = []
          e.permission_list.flat(Infinity).forEach(el => {
            arr2.push(el.permission_name)
          })
          e.permission_list = arr2.join(',')
        })
        console.log(self.table.tableData, "///self.table.tableData");
      }).finally(r => {
        self.table.loading = false
      })
    },
    // 系统列表
    getSystemList() {
      SystemrolrsList().then(res => {
        this.sysList = res.data
        this.query.system_id = res.data[0].system_id
        this.getList()
      })
    },

    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination
      const { field, order } = sorter
      this.query = Object.assign(this.query, { per_page: Number(pageSize), page: Number(current) })
      this.getList()
    },

    changeStatus($event, e) {
      let obj = {}
      this.table.tableData.forEach(el => {
        if (e.role_id == el.role_id) {
          el.role_status = $event ? 1 : 0
          obj = el
        }
      })
      this.permissionInfo.type = 3 //编辑
      this.permissionInfo.info = {
        role_id: obj.role_id,
        // system_id: obj.system_id,
        role_name: obj.permission_name,
        permission_id_list: [],
        role_status: obj.role_status
      }
      this.okclickmod()
    },
    onChangePermissiontype(e) {
      console.log('radio checked', e.target.value, this.permissionInfo.info.permission_type);
    },
    // 提交按钮
    okclickmod(e) {
      let data = {
        ...this.permissionInfo.info
      }
      if (this.permissionInfo.type == 3) {
        delete data.created_at
        delete data.updated_at
        delete data.system_id
        EditRules(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        })
      } else {
        createRules(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    async addRules(e, i) {
      this.permissionInfo.isDisabled = false
      this.permissionInfo.info = {
        role_id: undefined,
        role_name: undefined,
        permission_id_list: [],
        role_status: undefined,
        system_id: this.query.system_id
      }
      await this.getparentOptions()
      console.log(this.treeData);
      this.permissionInfo.visible = true
      let timer = setTimeout(() => {
        if (i == 3) {
          this.permissionInfo.title = '编辑角色'
          this.permissionInfo.info.role_id = e.role_id
          this.permissionInfo.info.system_id = undefined
          this.permissionInfo.type = 3
          this.openDatails()
          console.log(this.permissionInfo.info);
        } else {
          this.permissionInfo.title = '添加角色'
          this.permissionInfo.type = 1
          delete this.permissionInfo.info.role_id
          console.log(this.permissionInfo);

        }
        clearTimeout(timer)
        this.timer = ''
      }, 400)



    },

    openDatails() {
      Getrulesinfo({ role_id: this.permissionInfo.info.role_id }).then(res => {
     //
        res
        this.permissionInfo.info = Object.assign(this.permissionInfo.info, res.data)
        console.log(this.permissionInfo.info);
        this.permissionInfo.info.permission_id_list = this.permissionInfo.info.permission_id_list.split(',')
        console.log(this.permissionInfo.info);
        this.reload()
        // console.log(this.permissionInfo.info, "this.permissionInfo.info");
      })
    },

    getparentOptions() {
      let date = {
        filter: {
          system_id: this.query.system_id
        }
      }
      Getrolepermission(date).then(res => {
        this.treeRecursion(res.data)
        this.treeData = res.data
     //
        console.log(this.permissionInfo.info.permission_id_list);
      })
    },
    // 递归改变id key number
    treeRecursion(data) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el.permission_id) == 'number') {
          el.permission_id = el.permission_id.toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children);
      })
    },
    // 自定义展开关闭图标
    customExpandIcon(props) {
      console.log(props)
      // if(props.record.children.length > 0){
      if (props.expanded) {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">关闭详情</a-button></a>
      } else {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">查看详情</a-button></a>
      }
      // }else{
      //     return <span style={{marginRight:8 }}></span>
      // }
    },

    onExpand(expandedKeys) {
      console.log('onExpand', expandedKeys);
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onCheck(checkedKeys) {
      console.log('onCheck', checkedKeys);
      this.checkedKeys = checkedKeys;
    },
    onSelect(selectedKeys, info) {
      console.log('onSelect', info);
      this.permissionInfo.info.permission_id_list = selectedKeys;
    },
  },
  watch: {
    checkedKeys(val) {
      console.log('onCheck', val);
    },
  },

}
</script>
<style lang="less" scoped>
.personnelfiles {
  // padding: 12px;
  margin: 20px 12px 20px 20px;
  overflow: hidden;
  // background: #fff;
  // height: 100%;
  // min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  .filter-wrap {
    background: #fff;
    border-radius: 4px 0 0 4px;
  }
  /deep/ .ant-checkbox-wrapper {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}
.borderbox {
  margin-left: 4px;
  display: inline-block;
  border-radius: 6px;
  text-align: center;
  width: 20px;
  height: 18px;
  background: @btn2mianbgDColor;
  box-shadow: 0px 2px 3px 0px @btn2mianOutDColor,
    inset 0px -2px 0px 0px @btn2mianInsertDColor;
  border-radius: 4px;
  border: 1px solid @btn2mianboderDColor;
  line-height: 14px;

  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.bigdropdown_imga {
  display: none;
}
.bigdropdown_img1a {
  display: none;
}
.bigdropdown {
  position: relative;
  width: 100px;
  height: 18px;
  display: inline-block;
  line-height: 18px;
  .borderbox {
    position: absolute;
    top: 0;
    left: 29px;
    i {
      font-size: 8px;
      margin-top: -1px;
      transform: scale(0.83);
      display: inline-block;
      color: @btn2mianfontDColor;
    }
    &:hover {
      border: 1px solid @btn2mianboderHColor;
      i {
        color: @btn2mianfontHColor;
      }
    }
  }
}
/deep/ .bgchangenode {
  background: #ccc;
}
/deep/.ant-modal-header {
  padding-left: 24px;
}
/deep/.ant-modal-footer {
  padding-bottom: 32px;
}
.btn-padding {
  display: none;
  // background: rgba(216, 219, 236, 0.27);
  border-radius: 4px;
  color: @btn3mianFontDColor;
  background: #f5f5fa;
  border: none !important;
  box-shadow: none !important;
  &:hover {
    color: @btn3mianFontHColor !important;
    background-color: @btn3mianbgHColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  &:focus {
    color: @btn3mianFontFColor !important;
    background-color: @btn3mianbgFColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  // border: 1px solid @btn2mianboderDColor;
  // box-shadow: 0px 2px 4px 0px @btn2mianOutDColor, inset 0px -2px 0px 0px @btn2mianInsertDColor;
  // background-color: @btn2mianbgDColor;
  // color: @btn2mianfontDColor;
  // &:active {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor !important;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:focus {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:hover {
  //   color: @btn2mianfontHColor !important;
  //   border: 1px solid @btn2mianboderHColor !important;
  //   background-color: @btn2mianbgHColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutHColor, inset 0px -2px 0px 0px @btn2mianInsertHColor;
  // }
}
/deep/ .ant-table-header {
  // margin-top: -23px;
}
/deep/ .borderbox.ant-dropdown-open {
  border-color: @baseColor35 !important;
  color: @baseColor35 !important;
  i {
    color: @baseColor35 !important;
  }
}
/deep/
  .ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-cascader-menu-item:hover,
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-tree li .ant-tree-node-content-wrapper:hover,
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: @selectdownbgHColor !important;
  // color: #0A955D;
  color: @selectdownfontHColor;
  font-weight: 400;
}
.smallbtnlong {
  letter-spacing: -1px !important;
}
/deep/ .ant-table-body {
  overflow: hidden !important;
  overflow-y: scroll !important;
}
.meiye-icon {
  color: @primaryColor;
}
/deep/ .ant-input2 .ant-input {
  padding-top: 5px;
  &::placeholder {
    padding-top: 3px;
  }
}
.linebox {
  display: flex;
  padding: 12px 0;

  .lfbox {
    width: 110px;
    font-size: 14px;
    color: @fontColor5;
    text-align: left;
    .imgxing {
      width: 8px;
      height: 22px;
      color: red;
    }
  }

  .rgbox {
    font-size: 14px;
    color: @btnColorfont1;
    // width: calc(100% - 120px);
    position: relative;

    .tuijia {
      font-size: 14px;
      color: @fontColor6;
    }
  }
}
/deep/ .ant-upload-picture-card-wrapper .ant-upload {
  width: 32px !important;
  height: 32px !important;
  padding: 0;
}
/deep/ .ant-upload-select-picture-card i {
  font-size: 14px;
}
</style>